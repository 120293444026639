import menuReducer from '@/reducers/menu/menuSlice';
import sortingReducer from '@/reducers/sorting/sortingSlice';

import { configureStore } from '@reduxjs/toolkit';

import { prismicApi } from './api';

export const store = configureStore({
  reducer: {
    menu: menuReducer,
    sorting: sortingReducer,
    [prismicApi.reducerPath]: prismicApi.reducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(prismicApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
