import Footer from '@/components/Footer';
import Header from '@/components/Header';
import { store } from '@/reducers/store';

import { Box, ChakraProvider, Flex, LightMode } from '@chakra-ui/react';
import { PrismicPreview } from '@prismicio/next';
import { Libraries, useLoadScript } from '@react-google-maps/api';
import dayjs from 'dayjs';
import enAU from 'dayjs/locale/en-au';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import chakraTheme from 'lib/theme';
import { AppProps } from 'next/app';
import NextNProgress from 'nextjs-progressbar';
import { repositoryName } from 'prismicio';
import { Provider as ReduxProvider } from 'react-redux';

dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);
dayjs.locale('en-au', enAU);

const libraries: Libraries = ['places'];

function MyApp({ Component, pageProps }: AppProps) {
  useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_API_KEY ?? '',
    libraries,
  });
  return (
    <>
      <NextNProgress
        color='#FFFF00'
        options={{
          showSpinner: false,
        }}
      />
      <ReduxProvider store={store}>
        <ChakraProvider theme={chakraTheme}>
          <Flex direction={{ base: 'column', md: 'row' }}>
            <Header />
            <Box pt={{ base: 8, md: 12 }}>
              <LightMode>
                <PrismicPreview repositoryName={repositoryName}>
                  <Component {...pageProps} />
                </PrismicPreview>
              </LightMode>
            </Box>
          </Flex>
          <Flex bottom={0} justifyContent='center' left={0} pos={{ base: 'relative', lg: 'fixed' }}>
            <Footer m={8} />
          </Flex>
        </ChakraProvider>
      </ReduxProvider>
    </>
  );
}

export default MyApp;
