import { createIcon, extendTheme, ThemeConfig } from '@chakra-ui/react';
import { Lora, Work_Sans } from 'next/font/google';

const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

export const lora = Lora({
  subsets: ['latin'],
  display: 'swap',
});

export const workSans = Work_Sans({
  subsets: ['latin'],
  display: 'swap',
});

const theme = extendTheme({
  config,
  colors: {
    gray: {
      50: '#f1f0f5',
      100: '#d4d3db',
      200: '#b7b4c4',
      300: '#9b96ae',
      400: '#7e7899',
      500: '#645e80',
      600: '#4e4a63',
      700: '#383546',
      800: '#211f29',
      900: '#0b0a0f',
    },
    yellow: {
      50: '#FFFFE5',
      100: '#FFFFB8',
      200: '#FFFF8A',
      300: '#FFFF5C',
      400: '#FFFF2E',
      500: '#FFFF00',
      600: '#CCCC00',
      700: '#999900',
      800: '#666600',
      900: '#333300',
    },
  },
  fonts: {
    heading: `${lora.style.fontFamily}, serif`,
    body: `${workSans.style.fontFamily}, ui-serif, Georgia, Cambria, "Times New Roman", Times, serif`,
  },
  styles: {
    global: {
      body: {
        bg: `url('/noisy_net.webp')`,
        bgSize: '220px',
      },
    },
  },
});

// Custom Icons
export const HomeIcon = createIcon({
  displayName: 'HomeIcon',
  viewBox: '0 0 576 512',
  d: 'M573.3 250C576.6 252.9 576.9 257.1 573.1 261.3C571.1 264.6 566 264.9 562.7 261.1L512 217.1V448C512 483.3 483.3 512 448 512H128C92.65 512 64 483.3 64 448V217.1L13.3 261.1C9.997 264.9 4.941 264.6 2.012 261.3C-.9182 257.1-.6116 252.9 2.696 250L282.7 2.011C285.7-.6704 290.3-.6704 293.3 2.011L573.3 250zM128 496H208V328C208 314.7 218.7 304 232 304H344C357.3 304 368 314.7 368 328V496H448C474.5 496 496 474.5 496 448V202.9L288 18.69L80 202.9V448C80 474.5 101.5 496 128 496V496zM224 496H352V328C352 323.6 348.4 320 344 320H232C227.6 320 224 323.6 224 328V496z',
});
export const CalendarIcon = createIcon({
  displayName: 'CalendarIcon',
  viewBox: '0 0 448 512',
  d: 'M120 0C124.4 0 128 3.582 128 8V64H320V8C320 3.582 323.6 0 328 0C332.4 0 336 3.582 336 8V64H384C419.3 64 448 92.65 448 128V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V128C0 92.65 28.65 64 64 64H112V8C112 3.582 115.6 0 120 0zM432 192H16V448C16 474.5 37.49 496 64 496H384C410.5 496 432 474.5 432 448V192zM112 120V80H64C37.49 80 16 101.5 16 128V176H432V128C432 101.5 410.5 80 384 80H336V120C336 124.4 332.4 128 328 128C323.6 128 320 124.4 320 120V80H128V120C128 124.4 124.4 128 120 128C115.6 128 112 124.4 112 120z',
});
export const VideoIcon = createIcon({
  displayName: 'VideoIcon',
  viewBox: '0 0 576 512',
  d: 'M336 64h-288c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48h288c26.51 0 48-21.49 48-48v-288C384 85.49 362.5 64 336 64zM368 400c0 17.64-14.36 32-32 32h-288c-17.64 0-32-14.36-32-32v-288c0-17.64 14.36-32 32-32h288c17.64 0 32 14.36 32 32V400zM555.7 100.5c-12.75-6.734-28.16-5.859-39.97 2.25l-96.13 63.81c-3.656 2.453-4.656 7.422-2.219 11.09c2.438 3.703 7.406 4.641 11.09 2.25L524.7 116c7.125-4.859 15.88-5.391 23.56-1.359C555.6 118.5 560 125.7 560 133.9v244.1c0 8.203-4.406 15.42-11.78 19.3c-7.625 4.047-16.5 3.5-23.69-1.453l-96.13-63.73c-3.625-2.406-8.625-1.453-11.06 2.25c-2.438 3.688-1.438 8.641 2.25 11.09l96 63.66c6.594 4.531 14.25 6.844 21.97 6.844c6.188 0 12.41-1.5 18.09-4.5C568.4 404.8 576 392.2 576 377.1V133.9C576 119.7 568.4 107.2 555.7 100.5z',
});
export const BlogIcon = createIcon({
  displayName: 'BlogIcon',
  viewBox: '0 0 512 512',
  d: 'M400.4 20.71C422.2-1.376 457.8-1.478 479.8 20.48L491.7 32.41C513.6 54.28 513.6 89.73 491.7 111.6L283.2 320.2C275.5 327.9 265.8 333.2 255.1 335.4L169.6 353.3C166.1 353.9 164.3 353.1 162.3 351.2C160.4 349.3 159.6 346.5 160.2 343.9L178.1 258.2C180.3 247.7 185.6 238 193.1 230.3L400.4 20.71zM468.5 31.79C452.8 16.11 427.3 16.18 411.8 31.96L371.7 72.43L440 140.7L480.4 100.3C496 84.66 496 59.34 480.4 43.72L468.5 31.79zM193.8 261.5L178.3 335.2L251.8 319.7C259.5 318.1 266.4 314.4 271.9 308.9L428.7 152L360.5 83.8L204.5 241.6C199.1 247.1 195.4 253.1 193.8 261.5V261.5zM256 40C136.7 40 40 136.7 40 256C40 375.3 136.7 472 256 472C260.4 472 264 475.6 264 480C264 484.4 260.4 488 256 488C127.9 488 24 384.1 24 256C24 127.9 127.9 23.1 256 23.1C284.7 23.1 312.2 29.21 337.5 38.74L325.1 51.3C303.4 43.97 280.2 39.1 256 39.1V40zM460.1 187.6L473.5 175.1C482.9 200.3 488 227.5 488 256C488 331.1 427.1 392 352 392C333.1 392 316.8 388.5 300.1 382.1C296.9 380.5 294.9 375.8 296.6 371.7C298.2 367.6 302.9 365.6 307 367.3C320.9 372.9 336.1 376 352 376C418.3 376 472 322.3 472 256C472 232.1 468.1 209.1 460.1 187.6H460.1z',
});
export const TopicsIcon = createIcon({
  displayName: 'TopicsIcon',
  viewBox: '0 0 512 512',
  d: 'M256 16a240 240 0 1 1 0 480 240 240 0 1 1 0-480zm0 496A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM208 352c-4.4 0-8 3.6-8 8s3.6 8 8 8h96c4.4 0 8-3.6 8-8s-3.6-8-8-8H264V216c0-4.4-3.6-8-8-8H224c-4.4 0-8 3.6-8 8s3.6 8 8 8h24V352H208zm48-176a16 16 0 1 0 0-32 16 16 0 1 0 0 32z',
});
export const AboutIcon = createIcon({
  displayName: 'AboutIcon',
  viewBox: '0 0 512 512',
  d: 'M256 128C211.8 128 176 163.8 176 208S211.8 288 256 288c44.18 0 80-35.82 80-80S300.2 128 256 128zM256 272c-35.29 0-64-28.71-64-64s28.71-64 64-64s64 28.71 64 64S291.3 272 256 272zM256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 496c-54.04 0-103.8-18.17-143.1-48.43C112.3 386 162.4 336 224 336h64c61.61 0 111.7 50.02 111.1 111.6C359.8 477.8 310 496 256 496zM414.7 435.5C408.4 370.8 354.4 320 288 320H224c-66.42 0-120.4 50.78-126.7 115.5C47.55 391.5 16 327.4 16 256c0-132.3 107.7-240 240-240s240 107.7 240 240C496 327.4 464.4 391.5 414.7 435.5z',
});
export const MenuIcon = createIcon({
  displayName: 'MenuIcon',
  viewBox: '0 0 448 512',
  d: 'M0 88C0 83.58 3.582 80 8 80H440C444.4 80 448 83.58 448 88C448 92.42 444.4 96 440 96H8C3.582 96 0 92.42 0 88zM0 248C0 243.6 3.582 240 8 240H440C444.4 240 448 243.6 448 248C448 252.4 444.4 256 440 256H8C3.582 256 0 252.4 0 248zM440 416H8C3.582 416 0 412.4 0 408C0 403.6 3.582 400 8 400H440C444.4 400 448 403.6 448 408C448 412.4 444.4 416 440 416z',
});

export default theme;
