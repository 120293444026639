import { Box, BoxProps, Image } from '@chakra-ui/react';
import Link from 'next/link';

interface Props {
  boxProps: BoxProps;
}

function Logo(props: Props) {
  return (
    <Link href='/'>
      <Box {...props.boxProps}>
        <Image alt='Circlework' src='/logo.svg' w='100%' />
      </Box>
    </Link>
  );
}

export default Logo;
