import { Box, Flex, Link, StackProps, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';

function Footer(props: StackProps) {
  return (
    <Box
      as='footer'
      borderLeftWidth={4}
      borderWidth={1}
      color='chakra-border-color'
      fill='chakra-border-color'
      fontFamily='mono'
      p={2}
      {...props}
    >
      <Text fontSize='sm' mb={1}>
        &copy; Copyright {dayjs().year()} Circlework
      </Text>
      <Flex alignItems='center' h={3}>
        <Text fontSize='sm' mr={1}>
          Site developed by
        </Text>
        <Box h={3}>
          <Link
            _active={{ opacity: 1 }}
            _hover={{ opacity: 0.8 }}
            aria-label='META IIII'
            href='https://metaiiii.online'
          >
            <svg
              height='100%'
              version='1.1'
              viewBox='0 0 633.6 123.1'
              x='0'
              xmlSpace='preserve'
              xmlns='http://www.w3.org/2000/svg'
              y='0'
            >
              <Box as='g' fill='inherit'>
                <path d='M498.9 0h11.7v123.1h-11.7zM621.9 0h11.7v123.1h-11.7zM580.9 0h11.7v123.1h-11.7zM539.9 0h11.7v123.1h-11.7z' />
                <path d='M184.4 7.3h-.2c-21 .7-25.6 19.7-25.9 20.9v.1l-16.4 56.9-5 17.2s-.1.2-.2.6c-.8 3.3-4 19.9 16.2 20.2H84.7c9.1 0 15.8-5.2 20.1-10.4 3.6-4.3 6.2-9.4 7.8-14.8l20.5-70.6-66.2 95.8L54 21.8l-23.2 80.6s-6.1 20.7 14.8 20.7H0c4.6-.2 12.2-4.3 14.6-6 4.3-3.2 7.4-8.1 9.4-14.7l23-78.6c.6-1.8.8-3.8.4-5.7-.7-4.5-4.3-10.8-18.6-10.8h46.5l8.5 79.5 55.1-79.5h45.5zM271 79.2L255.4 123H153.2c21.2-.3 22.8-14.9 28.4-35.1.3-1 13.4-45.6 17.6-60.3.1-.2.1-.5.2-.7 1.8-6.4 4.9-19.4-14.8-19.6H283L270.7 46c11.4-38.7-42.1-32.3-42.1-32.3l-12.9 44.9s34.4 6 44.9-24.5l-3.1 9.5-15.1 47.6c9.5-29.9-28.7-26.4-28.7-26.4l-11.7 41c-1.6 5.6 2.5 11.3 8.4 11.5 17.5.5 46 2.6 60.6-38.1zM359.2 120.2c4.4 1.5 8.3 2.8 8.3 2.8h-18.1c3.3.1 6.6-1 9.8-2.8zM372.6 62.5c15.1-49.8-16.9-47.8-16.9-47.8l-21.3 73.5-6.9 23.9-.1.2v.1c0 2.9 1 5 2.6 6.5s3.8 2.5 6.3 3.1c3.8.9 8.1 1.1 12 1.1h-78.6c27.6 0 34.5-21.2 34.5-21.2l4-13.7L330 14.7c-8.9.3-16.3 2.2-22.3 5.8-6 3.6-17.4 15.6-22.4 24l11.8-37.2h93.1l-17.6 55.2z' />
                <path d='M419.7 120.8c-.1 0-.1 0 0 0-3.4 1.1-7.4 2-12.5 2.3h72.2c-16.6 0-19.9-9.6-20.5-13.3v-.1c0-.1-.1-.3-.1-.4 0-.2 0-.3-.1-.4v-.6c0-1 .1-4 .3-8.9L462.8 0l-84.4 100.2c-.1.1-.2.3-.3.4-6.1 8.3-13 15.7-20 19.6-3.2 1.8-6.6 2.8-9.8 2.8h49.4c-2.2-.1-4.1-.4-5.8-.7-20.5-3.9-6.6-19.8-6.6-19.8l13.8-16.8h35.8l-.6 14.1c0 5.2-.5 16.1-14.6 21zm-14.7-42L437.1 41l-2.2 37.7H405zM464 0z' />
                <path d='M458.8 108.9v-.6c-.1.3-.1.4 0 .6zM327.4 112.3c0 .1 0 .1 0 0z' />
              </Box>
            </svg>
          </Link>
        </Box>
      </Flex>
    </Box>
  );
}

export default Footer;
