import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PostCategory } from 'types';

type GridType = 'list' | 'grid';
type SortBy = 'first_publication_date' | 'last_publication_date' | 'title';

export interface SortingState {
  gridView: GridType;
  selectedCategories: PostCategory[];
  sortBy: SortBy;
}

const initialState: SortingState = {
  gridView: 'grid',
  selectedCategories: ['yoga', 'high-performance aging', 'i liq chuan'],
  sortBy: 'title',
};

export const sortingSlice = createSlice({
  name: 'sorting',
  initialState,
  reducers: {
    setGridView: (state, action: PayloadAction<GridType>) => {
      state.gridView = action.payload;
    },
    toggleCategory: (state, action: PayloadAction<PostCategory>) => {
      const index = state.selectedCategories.indexOf(action.payload);
      if (index > -1) {
        state.selectedCategories.splice(index, 1);
      } else {
        state.selectedCategories.push(action.payload);
      }
    },
    setSortBy: (state, action: PayloadAction<SortBy>) => {
      state.sortBy = action.payload;
    },
  },
});

export const { setGridView, toggleCategory, setSortBy } = sortingSlice.actions;

export default sortingSlice.reducer;
