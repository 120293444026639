import Logo from '@/components/Logo';
import { useAppDispatch, useAppSelector } from '@/reducers/hooks';
import { closeMenu, openMenu } from '@/reducers/menu/menuSlice';

import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  Stack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { MenuIcon } from 'lib/theme';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';

export default function Navbar() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const menuState = useAppSelector((state) => state.menu.isOpen);
  const isOpen = menuState === 'main';
  const dispatch = useAppDispatch();
  const onClose = () => dispatch(closeMenu());

  return isMobile ? (
    <>
      <MenuButton />
      <Drawer isOpen={isOpen} placement='left' size='lg' onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent background={`url('/noisy_net.webp')`}>
            <DrawerCloseButton right={10} rounded='full' size='lg' top={6} variant='outline' />
            <DrawerBody>
              <Flex alignItems='center' justifyContent='center' mt={16}>
                <NavLinks />
              </Flex>

              <Flex justifyContent='center' mb={2}>
                <Logo boxProps={{ w: '100%', maxW: '240px', mt: 10 }} />
              </Flex>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  ) : (
    <NavLinks />
  );
}

function NavLink({ href, children }: { href: string; children: ReactNode }) {
  const router = useRouter();
  const isActive = href === '/' ? router.pathname === href : router.pathname.includes(href);
  const dispatch = useAppDispatch();
  return (
    <Button
      _hover={{ textDecoration: 'none', borderColor: 'border', color: 'gray.200' }}
      borderBottomWidth={2}
      borderColor={isActive ? 'border' : 'transparent'}
      borderRadius='0'
      colorScheme='whiteAlpha'
      fontFamily='heading'
      fontSize='2xl'
      fontWeight={isActive ? 'bold' : 'medium'}
      textColor='white'
      textTransform='none'
      variant='link'
      onClick={() => {
        dispatch(closeMenu());
        router.push(href);
      }}
    >
      {children}
    </Button>
  );
}

function NavLinks() {
  return (
    <Stack alignItems='center' spacing={10}>
      <NavLink href='/'>Home</NavLink>
      <NavLink href='/classes'>Classes</NavLink>
      <NavLink href='/events'>Events</NavLink>
      <NavLink href='/blog'>Blog</NavLink>
      <NavLink href='/videos'>Videos</NavLink>
      <NavLink href='/about'>About</NavLink>
    </Stack>
  );
}

export function MenuButton() {
  const dispatch = useAppDispatch();
  return (
    <IconButton
      aria-label='Menu'
      icon={<MenuIcon />}
      pos='absolute'
      right={6}
      rounded='full'
      size='lg'
      top={6}
      variant='outline'
      onClick={() => dispatch(openMenu('main'))}
    />
  );
}
