// import { DataRequest } from '@/services/kassandra';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { FullCheckoutSession } from 'shared/types/stripe';
import { CreatePayment } from 'types';

export const prismicApi = createApi({
  reducerPath: 'prismicApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.NEXT_PUBLIC_URL}api/`,
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    createSubscription: builder.mutation<{ error: boolean; text?: string }, { email: string }>({
      query: (body) => ({
        url: `subscribe`,
        method: 'POST',
        body,
      }),
    }),
    // Stripe
    getCheckoutSession: builder.query<FullCheckoutSession, string>({
      query: (id) => `checkout/${id}`,
    }),
    createCheckoutSession: builder.mutation<{ id: string }, CreatePayment>({
      query: (body) => ({
        url: `checkout`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useCreateSubscriptionMutation,
  useGetCheckoutSessionQuery,
  useCreateCheckoutSessionMutation,
} = prismicApi;
