import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type MenuType = 'main' | 'join' | 'payment' | 'none';

export interface MenuState {
  isOpen: MenuType;
}

const initialState: MenuState = {
  isOpen: 'none',
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    openMenu: (state, action: PayloadAction<MenuType>) => {
      state.isOpen = action.payload;
    },
    closeMenu: (state) => {
      state.isOpen = 'none';
    },
  },
});

export const { openMenu, closeMenu } = menuSlice.actions;

export default menuSlice.reducer;
