import { Flex, Heading, MenuGroup, MenuItem, MenuList, useBreakpointValue } from '@chakra-ui/react';
import { ContextMenu } from 'chakra-ui-contextmenu';
import { SiFacebook, SiInstagram, SiMailchimp, SiPrismic, SiStripe } from 'react-icons/si';

import Logo from './Logo';
import Navbar from './Navbar';

function Header() {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return isMobile ? (
    <Flex alignItems='center' as='header' flexDir='row' justifyContent='center'>
      <Heading fontFamily='body' letterSpacing={3} my={6} size='lg'>
        CIRCLEWORK
      </Heading>
      <Navbar />
    </Flex>
  ) : (
    <Flex alignItems='center' as='header' flexDir='column' pt={6} width='320px'>
      <Flex my={8}>
        <ContextMenu<HTMLDivElement>
          renderMenu={() => (
            <MenuList>
              <MenuGroup title='Admin'>
                <MenuItem
                  as='a'
                  href={`https://${process.env.NEXT_PUBLIC_PRISMIC_REPO}.prismic.io`}
                  icon={<SiPrismic />}
                  target='_blank'
                >
                  Prismic
                </MenuItem>
                <MenuItem
                  as='a'
                  href='https://login.mailchimp.com'
                  icon={<SiMailchimp />}
                  target='_blank'
                >
                  Mailchimp
                </MenuItem>
                <MenuItem
                  as='a'
                  href='https://dashboard.stripe.com/dashboard'
                  icon={<SiStripe />}
                  target='_blank'
                >
                  Stripe
                </MenuItem>
              </MenuGroup>
              <MenuGroup title='Social'>
                <MenuItem
                  as='a'
                  href='https://facebook.com/circlework.training'
                  icon={<SiFacebook />}
                  target='_blank'
                >
                  Facebook
                </MenuItem>
                <MenuItem
                  as='a'
                  href='https://instagram.com/circlework.training'
                  icon={<SiInstagram />}
                  target='_blank'
                >
                  Instagram
                </MenuItem>
              </MenuGroup>
            </MenuList>
          )}
        >
          {(ref) => (
            <div ref={ref}>
              <Logo boxProps={{ minW: '160px', maxW: '320px' }} />
            </div>
          )}
        </ContextMenu>
      </Flex>
      <Navbar />
    </Flex>
  );
}

export default Header;
